export const API_URL = process.env.REACT_APP_API_URL;

export const VALLGES_CLIENT_ID = 1;

export const USER_ROLES = {
    admin: 1,
    vallgesacc: 2,
    customer: 3,
    operator: 5
}

export const USER_ROLES_OPTIONS = [
    { label: 'Administrador', value: 1 },
    { label: 'Usuario Vallges', value: 2 },
    /* { label: 'Usuario Ejecutivo Cuenta', value: 4 },
    { label: 'Usuario Supervisor Ejecutivo', value: 5 },
    { label: 'Usuario Servicio', value: 6 },
    { label: 'Usuario Creador', value: 7 }, */
    { label: 'Cliente Administrador', value: 3 },
    { label: 'Cliente', value: 4 },
    { label: 'Operador Vallges', value: 5 },
]

export const CLIENT_USER_ROLES_OPTIONS = [
    { label: 'Cliente Administrador', value: 8 },
    { label: 'Cliente', value: 3 },
]

export const BALANCE_OPTIONS = [
    { label: 'Con naturaleza', value: 1 },
    { label: 'Sin naturaleza', value: 2 },
    { label: 'Sin naturaleza sin saldo', value: 3 },
]


export const ASSOCIATE_TYPE = ["Contable", "Legal", "Marketing", "Estrategia"];

export const ASSOCIATE_TYPE_FORMATTED = ASSOCIATE_TYPE.map(type => ({
    value: type,
    label: type
}));


export const EXPENSE_TYPE = ["Esfuerzo Interno", "Costo Proveedor"];

export const EXPENSE_TYPE_FORMATTED = EXPENSE_TYPE.map(zone => ({
    value: zone,
    label: zone
}));


export const LOG_TYPES = [
    { label: 'Todos', value: null },
    { label: 'Sin información', value: 1 },
    { label: 'Sin clasificación', value: 2, key: 'VisualizarLogTipoDeGastoFaltante' },
    { label: 'Cuenta agregada', value: 3, key: 'VisualizarLogNuevaCuentaAgregada' },
    { label: 'Cuenta modificada', value: 4, key: 'VisualizarLogBalanceDeCuentaModificado' },
    { label: 'Error de validación', value: 5 },
    { label: 'Sin descripción', value: 6, key: 'VisualizarLogDescripciónFaltanteEnLaCuenta' },
    { label: 'Sin EEFF', value: 7, key: 'VisualizarLogCuentasEeFfFaltanteEnLaCuenta' },
    { label: 'Sin Activo/Pasivo', value: 8, key: 'VisualizarLogEstructuraActivoPasivoFaltante' },
    { label: 'Sin Partida G&P', value: 9, key: 'VisualizarLogPartidaG&PFaltante' },
    { label: 'Sin Variable Fijo', value: 10 },
    { label: 'Sin Partida de Flujo', value: 11, key: 'VisualizarLogPartidaDeFlujoFaltante' },
    { label: 'Falta este mes por importar', value: 12 },
    { label: 'Descuadre al importar este mes', value: 13 },
    { label: 'No se pudo calcular flujo de caja', value: 14 },
]


export const LOG_STATUS = [
    { label: 'Sin resolución', value: 0, type: 'danger' },
    { label: 'Completado', value: 1, type: 'success' },
]


export const OPTION_GROUPS_NAMES = {
    segments: 'Segmentos',
    sectors: 'Sectores',
    flow_headings: 'Partidas de Flujo',
    account_classifications: 'Tipos de cuenta',
    eeff_accounts: 'Cuentas EE.FF',
    fiscal_risks: 'Cuentas de Análisis',
    gp_items: 'Partidas G&P',
    structure_items: 'Estructuras Activo/Pasivo',
    cost_expense_accounts: 'Tipo de gasto',
    referrals: 'Referidos',
    associates: 'Asociados'
}


export const UPLOAD_TYPES = [
    { label: 'Inicial', value: 1 },
    { label: 'Recurrente', value: 2 },
]

export const FISCAL_MONTHS = [
    { label: 'Enero (Cierre Diciembre)', value: 'enero' },
    { label: 'Abril (Cierre Marzo)', value: 'abril' },
    { label: 'Julio (Cierre Junio)', value: 'julio' },
    { label: 'Octubre (Cierre Septiembre)', value: 'octubre' },
]

export const REPORT_TYPES = {
    logs: 'logs',
    clients: 'clients',
    associates: 'clients-by-associates',
    referrals: 'clients-by-referrals',
    balanceflow: 'clients-by-balance-flow',
    balancecashflow: 'clients-by-balance-flow-with-cashflow',
    balancewithbalance: 'clients-by-balance-flow-with-balance',
}

export const COPYRIGHT_URL = '//google.com';