import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

// Components

import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import Modal from '../../components/UI/Modal/Modal';
import ReportLogs from '../../components/forms/ReportLogs/ReportLogs';
import ReportClients from '../../components/forms/ReportClients/ReportClients';
import ReportClientsByAssociates from '../../components/forms/ReportClientsByAssociates/ReportClientsByAssociates';
import ReportClientsByReferrals from '../../components/forms/ReportClientsByReferrals/ReportClientsByReferrals';
import ReportBalanceFlow from '../../components/forms/ReportBalanceFlow/ReportBalanceFlow';
import ReportBalanceCashFlow from '../../components/forms/ReportBalanceCashFlow/ReportBalanceCashFlow';
import ReportBalanceWithBalance from '../../components/forms/ReportBalanceWithBalance/ReportBalanceWithBalance';

// Styles

import './Styles.scss';

export default function Reports() {
    const [showLogReportModal, setShowLogReportModal] = useState(false);
    const [showClientReportModal, setShowClientReportModal] = useState(false);
    const [showClientsByAssociateReportModal, setShowClientsByAssociateReportModal] = useState(false);
    const [showClientsByReferralReportModal, setShowClientsByReferralReportModal] = useState(false);
    const [showBalanceFlowReportModal, setShowBalanceFlowReportModal] = useState(false);
    const [showBalanceCashFlowReportModal, setShowBalanceCashFlowReportModal] = useState(false);
    const [showBalanceWithBalanceReportModal, setShowBalanceWithBalanceReportModal] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Generar Reportes'}/>

                <div className='reports-buttons-list'>
                    {/*<button className='reports-button' onClick={() => setShowLogReportModal(true)}>*/}
                    {/*    <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />*/}
                    {/*    <p>*/}
                    {/*        Reporte de logs*/}
                    {/*    </p>*/}
                    {/*</button>*/}

                    {userPermissions?.VisualizarReporteDeClientes &&
                    <button className='reports-button' onClick={() => setShowClientReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                            Reporte de clientes
                        </p>
                    </button>}

                    {userPermissions?.VisualizarReporteDeClientesPorAsociado &&
                    <button className='reports-button' onClick={() => setShowClientsByAssociateReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                            Reporte de clientes por asociados
                        </p>
                    </button>}

                    {userPermissions?.VisualizarReporteDeClientesReferidos &&
                    <button className='reports-button' onClick={() => setShowClientsByReferralReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                            Reporte de clientes por referidos
                        </p>
                    </button>}

                    {userPermissions?.VisualizarReportesDataPlana &&
                    <button className='reports-button' onClick={() => setShowBalanceFlowReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                            Reporte cliente data plana
                        </p>
                    </button>}

                    {userPermissions?.VisualizarReportesDataPlanaFlujoDeCaja &&
                    <button className='reports-button' onClick={() => setShowBalanceCashFlowReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                        Reporte cliente data plana flujo de caja
                        </p>
                    </button>}

                    {userPermissions?.VisualizarReportesDataPlanaBalances &&
                    <button className='reports-button' onClick={() => setShowBalanceWithBalanceReportModal(true)}>
                        <FontAwesomeIcon className='reports-button-icon' icon={faFileInvoice} />
                        <p>
                        Reporte cliente data plana balances
                        </p>
                    </button>}

                </div>

            </PageWrapper>

            {/* Generate Logs Report Modal */}

            {/*<Modal show={showLogReportModal} title={'Reporte de Logs'}>*/}

            {/*    <ReportLogs setShowLogReportModal={setShowLogReportModal} />*/}

            {/*</Modal>*/}

            {/* Generate Logs Report Modal */}

            <Modal show={showClientReportModal} title={'Reporte de Clientes'}>

                <ReportClients setShowClientReportModal={setShowClientReportModal} />

            </Modal>

            {/* Generate Clients by Associate Report Modal */}

            <Modal show={showClientsByAssociateReportModal} title={'Reporte de Clientes por Asociados'}>

                <ReportClientsByAssociates setShowClientsByAssociateReportModal={setShowClientsByAssociateReportModal} />

            </Modal>


            {/* Generate Clients by Referral Report Modal */}

            <Modal show={showClientsByReferralReportModal} title={'Reporte de Clientes por Referidos'}>

                <ReportClientsByReferrals setShowClientsByReferralReportModal={setShowClientsByReferralReportModal} />

            </Modal>

            
            {/* Generate Balance Flow by Client Report Modal */}

            <Modal show={showBalanceFlowReportModal} title={'Reporte cliente data plana'}>

                <ReportBalanceFlow setShowBalanceFlowReportModal={setShowBalanceFlowReportModal} />

            </Modal>

    
            {/* Generate Balance Cash Flow by Client Report Modal */}

            <Modal show={showBalanceCashFlowReportModal} title={'Reporte data plana flujo de caja'}>

                <ReportBalanceCashFlow setShowBalanceCashFlowReportModal={setShowBalanceCashFlowReportModal} />

            </Modal>


            {/* Generate Balance With Balance by Client Report Modal */}

            <Modal show={showBalanceWithBalanceReportModal} title={'Reporte cliente data plana balances'}>

                <ReportBalanceWithBalance setShowBalanceWithBalanceReportModal={setShowBalanceWithBalanceReportModal} />

            </Modal>

        </div>
  )
}
